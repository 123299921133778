.content-top {
  height: 590px;
  margin-top: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.m-text {
  font-size: 18px;
  line-height: 0.1em;
  color: rgba(128, 75, 74, 1);
  font-weight: 600;
}
.l-text {
  font-size: 48px;
  font-weight: 900;
  line-height: 1.6em;
  color: rgba(128, 75, 74, 1);
  letter-spacing: 0.08em;
}
.text-blob {
    width: 350px;
    color: rgba(80,80,80,1);
}
.content-top-min {
    display: flex;
    flex-direction: column;
    height: 800px;
}