.cc {
  display: flex;
  width: 100vw;
  border: 1px solid rgba(220, 220, 220, 1);
}
.cc-child {
  display: flex;
  padding-top: 45px;
  padding-bottom: 45px;
  text-align: center;
  align-items: center;
  letter-spacing: 1px;
  line-height: 1.2em;
  justify-content: center;
  align-items: center;
  border-right: 1px solid rgba(220, 220, 220, 1);
}

.tab-content {
  display: flex;
  height: 60px;
  margin-top: 15px;
  margin-bottom: 25px;
  font-family: Open Sans, Arial, sans-serif;
  border-bottom: 1px solid rgba(220, 220, 220, 1);
}
.tab {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  width: "fit-content";
  opacity: 0.9;
  transition: 0.3s all;
}
.tab:hover {
  background-color: white;
  cursor: pointer;
  opacity: 1;
  border-bottom: 2px solid rgba(25, 43, 86, 0.4);
}
.tab-selected {
  background-color: white !important;
  border-bottom: 2px solid rgba(25, 43, 86, 1);
  opacity: 1;
  color: rgba(128, 75, 74, 1);
}
.cross-container {
  display: flex;
  height: 100%;
  align-items: center;
  padding-left: 15px;
  font-size: 16px;
  font-weight: 600;
  width: 200px;
  border-right: 1px solid rgba(200, 200, 220, 1);
  height: 40px;
  margin-right: 15px;
}
.header-tab-item {
  display: flex;
  height: 100%;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 16px;
  letter-spacing: 0.12em;
}
.header-tab-item:hover {
  cursor: pointer;
}
.menu-clickable {
  display: flex;
  height: 50px;
  width: 50px;
  border-radius: 50px;
  align-items: center;
  justify-content: center;
}
.menu-clickable:hover {
  cursor: pointer;
  background-color: white;
  box-shadow: 5px 5px 10px rgba(50, 50, 50, 0.1),
    -5px -5px 10px rgba(50, 50, 50, 0.1);
}
.menu-container {
  position: absolute;
  z-index: 1000;
  background-color: white;
  top: 0;
  right: -300px;
  height: 100vh;
  width: 300px;
  box-shadow: 5px 5px 10px rgba(50, 50, 50, 0.1),
  -5px -5px 10px rgba(50, 50, 50, 0.1);
}
.menu-open {
    right: 0;
    transition: 0.3s all ease-in;
}
.menu-closed {
    right: -300px;
    transition: 0.3s all ease-in;
}
.menu-close-icon {
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 25px;
}
.menu-item {
    margin-top: 50px;
}
.menu-footer {
    display: flex;
    flex-grow: 1;
    height: 75%;
    align-items: flex-end;
}