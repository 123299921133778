.social-banner {
  height: 150px;
  display: flex;
  background-color: #804b4a;
  margin-bottom: 45px;
  align-items: center;
  justify-content: center;
}
.social-banner-wrap {
    display: flex;
    height: 40px;
    width: 40px;
    border: 1px solid grey;
    margin: 25px;
    padding: 5px;
    border-radius: 6px;
    background-color: grey;
    box-shadow: 0px 1px 10px 0px rgb(0 0 0 / 20%);
}