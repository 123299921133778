.App {
  display: flex;
  flex-direction: column;
  font-family: "Abhaya Libre", Georgia, "Times New Roman", serif;
  font-family: Avenir;
}
div {
  line-height: 1.6;
}
.header {
  display: flex;
  width: 100%;
  color: black;
  height: 80px;
  position: fixed;
  top: 0;
  background-color: white;
  transition: all 0.3s ease;
  border-bottom: 1px solid rgba(220, 220, 220, 1);
  z-index: 205;
  align-items: center;
}

.header-sm {
  height: 55px !important;
  transition: all 0.3s ease;
}

.header-grow {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: flex-end;
  margin-right: 25px;
}

.book-now-button {
  display: flex;
  background-color: rgba(57, 52, 83, 1);
  color: white;
  padding: 8px 12px;
  border-radius: 6px;
  font-weight: 600;
}
.body-container {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
  background-blend-mode: multiply;
  background-color: rgba(200, 200, 200, 0.5);
  margin-top: 40px;
}

.profile-card {
  display: flex;
  box-shadow: 0px 2px 18px 0px rgb(0 0 0 / 60%);
  border-radius: 6px;
  margin: 25px;
  flex-direction: column;
}

.z-10 {
  z-index: 10;
}

.profile-image {
  border-radius: 6px;
  height: auto;
}
.dflex {
  display: flex;
}
.text-card {
  display: flex;
  flex-direction: column;
  width: "fit-content";
  height: 400px;
  width: 440px;
  justify-content: flex-start;
  font-family: "Abhaya Libre";
  margin-top: 25px;
}

.text-card-header {
  display: flex;
  font-family: "Abhaya Libre", Georgia, "Times New Roman", serif;
  font-weight: 800;
  font-size: 45px;
  letter-spacing: 1px;
  line-height: 1.2em;
  justify-content: center;
}
.text-card-content {
  display: flex;
  line-height: 2.1em;
  margin-top: 15px;
  -webkit-text-size-adjust: 100%;
  font-family: "Nunito", Helvetica, Arial, Lucida, sans-serif;
  font-weight: 800;
}
.icon-container {
  display: flex;
  flex-grow: 1;
  justify-content: center;
}

.icon-wrapper {
  display: flex;
  height: 30px;
  width: 30px;
  border: 1px solid grey;
  margin: 5px;
  padding: 5px;
  border-radius: 6px;
  background-color: grey;
  box-shadow: 0px 1px 10px 0px rgb(0 0 0 / 20%);
}
.icon-wrapper:hover {
  background-color: black;
  transition: all 0.5s ease;
  cursor: pointer;
}
.flex-end {
  align-items: flex-end;
}
.quote-icon {
  height: 24px;
  width: 25px;
  display: flex;
}
.quote-card {
  display: flex;
  font-size: 18px;
  min-height: 90px;
  font-weight: bold;
  flex-direction: row !important;
  color: rgba(128, 75, 74, 1);
  line-height: 1.6em;
  font-family: "Abhaya Libre", Georgia, "Times New Roman", serif;
}
.flex-1 {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  padding: 1px;
  text-align: center;
}
.content-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}
.m-top-15 {
  margin-top: 15px;
}
.book-image {
  height: 255px;
  border-radius: 4px;
  border: 3px solid rgba(128, 75, 74, 0.3);
  width: 255px;
}
.idea-card {
  display: flex;
  flex-grow: 1;
  background-color: white;
  flex-direction: column;
  border-radius: 6px;
  padding: 15px;
  margin: 5px;
  box-shadow: 0px 1px 10px 0px rgb(0 0 0 / 20%);
}
.ic-title {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  font-family: "Abhaya Libre", Georgia, "Times New Roman", serif;
  font-weight: 800;
  font-size: 55px;
  padding-bottom: 15px;
  color: rgba(128, 75, 74, 1);
}
.ic-body {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  font-family: "Abhaya Libre", Georgia, "Times New Roman", serif;
  font-weight: 500;
  font-size: 15px;
  color: #666666;
}
.border-top {
  border-top: 1px solid rgba(220, 220, 220, 1);
}

.footer {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.footer-info {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 45px;
  margin-bottom: 15px;
}
.footer-info-min {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: 45px;
  margin-bottom: 15px;
}
.footer-contact-min {
  margin-top: 5px;
  margin-bottom: 5px;
}
.footer-icons {
  display: flex;
  margin-bottom: 45px;
}

a {
  color: white !important;
}

.footer-contact {
  display: flex;
  align-items: center;
  margin-left: 15px;
  font-size: 18px;
}

.footer-mailto {
  color: black !important;
}
.app-content {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  font-size: 18px;
  margin-bottom: 25px;
  flex-direction: column;
  z-index: 100;
}
.app-content-center {
  display: flex;
  flex-direction: column;
  width: 90vw;
}

.copy-contact {
  display: flex;
  margin-top: 15px;
  background-color: rgba(217, 236, 219, 1);
  padding: 12px;
  border-radius: 4px;
  border: 1px solid rgba(209, 232, 212, 1);
  font-weight: 600;
  flex-direction: column;
  padding-top: 25px;
  padding-bottom: 25px;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.dflex {
  display: flex;
}

.speaking-image {
  box-shadow: 0px 2px 18px 0px rgb(0 0 0 / 60%);
  border-radius: 4px;
  width: 100%;
}
.speaking-image-large {
  box-shadow: 0px 2px 18px 0px rgb(0 0 0 / 60%);
  border-radius: 4px;
  width: 100%;
}
.speaking-image-container {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  align-items: flex-start;
  flex-grow: 1;
}
.m-top-25 {
  margin-top: 25px;
}
.image-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  width: 100%;
  height: 100%;
}
.image-container {
  display: flex;
  width: 100%;
}
.image-text-title {
  display: flex;
  font-weight: bold;
  font-size: 22px;
  margin-bottom: 15px;
}
.key-speaking-topics {
  display: flex;
  height: 100%;
  width: 100%;
  flex-wrap: wrap;
}
.key-topic {
  display: flex;
  flex-grow: 1;
  border: 1px solid rgba(220, 220, 220, 1);
  padding: 20px;
  margin: 20px;
  border-radius: 90px;
  box-shadow: 0px 1px 10px 0px rgb(0 0 0 / 20%);
  color: rgba(128, 75, 74, 1);
  line-height: 1.6em;
  font-weight: 700;
  align-items: center;
  justify-content: center;
}
.dflex {
  display: flex;
}
.flex-column {
  display: flex;
  flex-direction: column;
} 